html[data-theme='light'] {
  --h-color: #000;
  --h-color-blur: rgba(0,0,0,0.7);
  --h-color-blurrier: rgba(0,0,0,0.5);
  --h-color-blurriest: rgba(0,0,0,0.1);
  --h-color-background-level-1: #fff;
  --h-color-background-level-2: #f8f8f8;
  --h-color-background-level-3: #e9e9e9;
  --h-color-background-level-4: rgba(255,255,255,0.6);
  --h-color-theme: #f0506e;
  --h-button-color: #000;
  --h-button-color-background: #fff;
  --h-button-color-hover: #fff;
  --h-button-color-hover-background: #000;
}
